import React from 'react';

// vendor components & lib
import { graphql, StaticQuery } from 'gatsby';

// components
import Header from 'components/common/header';
import Layout from 'components/common/layout';
import ImageAsBackground from 'components/common/image-as-background';
import TextSpoiler from 'components/common/text-spoiler';

// styles
import 'stylesheets/faq/index.scss';

// component function
function FAQPage({ data }) {
  const { image, allContentfulResourceLibraryFaqCategory } = data;

  const imageChildFluid = image.childImageSharp.fluid;
  const resourceLibraryFaqCategoryNodes = (
    allContentfulResourceLibraryFaqCategory.edges || []
  )
    .map(edge => edge.node)
    .filter(node => !!node.items);

  return (
    <Layout>
      <main>
        <div className="faq_top-section">
          <Header styleType="dark" showLogo />

          <ImageAsBackground image={imageChildFluid} />

          <div className="faq_top-section_content">
            <h1 className="faq_top-section_title">FAQ</h1>
          </div>

          <div className="faq_top-section_overlay" />
        </div>

        <div className="faq_content">
          {resourceLibraryFaqCategoryNodes.map(({ id, title, items }) => (
            <FAQItem key={id} title={title} items={items} />
          ))}
        </div>
      </main>
    </Layout>
  );
}

function FAQItem({ title, items }) {
  if (!items) {
    return <></>;
  }

  return (
    <div className="faq_item">
      <h1 className="faq_item_title">{title}</h1>

      {items.map(({ id, question, answer }) => {
        const answerHtml =
          ((answer || {}).childMarkdownRemark || {}).html || '';
        return <TextSpoiler key={id} title={question} text={answerHtml} />;
      })}
    </div>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "faq-page.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allContentfulResourceLibraryFaqCategory(
          limit: 100
          sort: { fields: order }
        ) {
          totalCount
          edges {
            node {
              id
              title
              items {
                id
                question
                answer {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FAQPage data={data} {...props} />}
  />
);
